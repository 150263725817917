import { ExpectedValueFormulaCaseEnum, ExpectedValueFormulaEnum } from "../Common/Enums/ExpectedValueFormulaEnum";

export type EVSportBookType = {
  betSite: string;
  weight: number;
  widthWeight: number;
};

export type ExpectedValueFormulaWeightType = {
  formula: ExpectedValueFormulaEnum;
  weight: number;
};

export const defaultExpectedValueWeights: string = JSON.stringify([
  { betSite: "PINNACLE", weight: 1 },
  { betSite: "CIRCA", weight: 1 },
]);

export const defaultExpectedValueFormulaCase = ExpectedValueFormulaCaseEnum.WORST_CASE;

export function convertQueryToApiVariable(expectedValueFormulaWeight: number[] | undefined) {
  if (!expectedValueFormulaWeight) return undefined;
  return Object.values(ExpectedValueFormulaEnum).reduce<ExpectedValueFormulaWeightType[]>((acc, curr, i) => {
    acc.push({
      formula: curr,
      weight: expectedValueFormulaWeight[i] || 0,
    });
    return acc;
  }, []);
}

export const getParsedExpectedValueWeights = (expectedValueWeights: string): EVSportBookType[] => {
  const ew = JSON.parse(expectedValueWeights) as EVSportBookType[];
  return ew.filter((weight) => weight.weight !== 0);
};

export const parseExpectedValueWeights = (expectedValueWeights: EVSportBookType[]) => JSON.stringify(expectedValueWeights);
