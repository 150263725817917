import { gql } from '@apollo/client';
import { IGameType, IGameType as IGameTypeEnum } from "../Common/Fragments/IGameTypeFragment";

const gameTypes = Object.values(IGameType);

const playerTypes = [
  'NFLPlayerType', 'NBAPlayerType', 'NCAAFPlayerType', 'NHLPlayerType',
  'NCAABPlayerType', 'EuroLeagueBBPlayerType', 'EUFAChampionsPlayerType',
  'LaLlgaPlayerType', 'EnglishPremierPlayerType', 'FrenchLigue1PlayerType',
  'GermanBundesligaPlayerType', 'ItalianSeriaAPlayerType', 'MLBPlayerType',
  'TennisPlayerType', 'WNBAPlayerType', 'UEFAEuroPlayerType', 'CopaAmericaPlayerType',
  'CFLPlayerType', 'MLSPlayerType', 'BrazilSerieAPlayerType', 'NBASummerPlayerType',
  'OlympicsBasketballPlayerType', 'OlympicsBasketballWomenPlayerType',
  'OlympicsSoccerPlayerType', 'OlympicsSoccerWomenPlayerType', 'UEFAEuropaPlayerType',
  'CBAPlayerType',
];

// Generate IGameType fragments dynamically
const gameTypeFragments = gameTypes.map((gameType, index) => `
    fragment IGameType${gameType}${index}Fragment on ${gameType} {
      id
      awayTeam {
        id
        name
        abbreviations
        ${gameType !== IGameTypeEnum.TennisGameType ? "city" : ""}
      }
      homeTeam {
        id
        name
        abbreviations
        ${gameType !== IGameTypeEnum.TennisGameType ? "city" : ""}
      }
      startDateTime
    }
  `).join('\n');

const playerFragments = playerTypes.map((playerType, index) => `
    fragment Player${playerType}${index}Fragment on ${playerType} {
      id
      name
    }
  `).join('\n');

// Main GraphQL query
export const SEARCH_LIVE_QUERY = gql`
  query Search($query: String!) {
    search {
      live(query: $query) {
        games {
          ${gameTypes.map((gameType, index) => `... on ${gameType} {
            ...IGameType${gameType}${index}Fragment
          }`).join('\n')}
        }
        players {
          game {
            ${gameTypes.map((gameType, index) => `... on ${gameType} {
            ...IGameType${gameType}${index}Fragment
          }`).join('\n')}
          }
          player {
            ${playerTypes.map((playerType, index) => `... on ${playerType} {
              ...Player${playerType}${index}Fragment
            }`).join('\n')}
          }
        }
      }
    }
  }
  ${gameTypeFragments}
  ${playerFragments}
`;