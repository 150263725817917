import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "@/navigation";
import { Stack, styled } from "@mui/material";
import LogoName from "../Icons/LogoName";
import ServerStatus from "../ServerStatus/ServerStatus";
import ServerStatusHeaderMessage from "../ServerStatus/ServerStatusHeaderMessage";
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from "./common";
import HeaderUserBtn from "./HeaderUserBtn";
import SearchLive from "../SearchLive/SearchLive";

const StyledAppBar = styled(AppBar)(
  ({ theme }) => `
    position: static;
    background-image: none;
    box-shadow: none;
    z-index: 10;
    height: ${HEADER_HEIGHT}px;
    ${theme.breakpoints.down("md")} {
    height: ${HEADER_HEIGHT_MOBILE}px;
  }
`,
);

type HeaderProps = {
  endComponent?: React.ReactNode;
  searchComponent?: React.ReactNode;
};

function Header({ endComponent, searchComponent }: HeaderProps) {

  const userIconButton = (
    <Stack direction="row" sx={{ flexGrow: 0 }}>
      <Box sx={{ display: { md: "flex", xs: "none" }, minWidth: 60 }}>
        <ServerStatus />
      </Box>
      <HeaderUserBtn />
    </Stack>
  );

  return (
    <StyledAppBar enableColorOnDark id="app-header">
      <Container maxWidth={false} sx={{ flexGrow: 1 }}>
        <Toolbar disableGutters sx={{ height: "100%" }}>
          <Box sx={{ display: { md: "flex", xs: "none" } }}>
            <Link href="/" style={{ display: "flex", alignItems: "center" }}>
              <LogoName />
            </Link>
          </Box>
          <Box sx={{ display: { md: "none", xs: "flex" } }}>
            <Link href="/" style={{ display: "flex", alignItems: "center" }}>
              <LogoName width={80} height={44} />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{
              display: { md: "flex", xs: "none" },
              alignItems: "center",
              gap: 2,
            }}
          >

            <ServerStatusHeaderMessage />
            <SearchLive />
            {userIconButton}
          </Box>

          {/* Mobile Header */}
          <Box
            sx={{
              display: { md: "none", xs: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            gap={1}
          >

            {searchComponent}
            <Box sx={{ minWidth: 50 }}>
              <ServerStatus />
            </Box>
            {userIconButton}
            {endComponent}
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}

export default Header;