
import React from "react";
import { Stack, IconButton, Box, Typography, useTheme } from "@mui/material";
import DiscordBrandIcon from "@/assests/icons/DiscordBrandIcon";
import XIcon from "@mui/icons-material/X";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

function SocialLinks() {
    const theme = useTheme();
    return (
        <Stack width="100%" gap={0.5} alignItems="center" justifyContent="center" py={2}>
            <Typography variant="caption" sx={{
                opacity: 0.24,
                textAlign: "center",
                width: "100%",
            }}>Find Us Online</Typography>
            <Box sx={{
                height: "1px",
                width: "100%",
                opacity: 0.45,
                background: `linear-gradient(to right, transparent, ${theme.palette.customBackground.custom}, transparent)`
            }} />

            <Stack direction="row" gap={4} mt={0.3}>
                <IconButton
                    component="a"
                    href="https://discord.gg/9dsyWsbnRx"
                    target="_blank"
                    rel="noreferrer noopener"
                    sx={{
                        background: theme.palette.grayScale.sixpercent,
                        borderRadius: "8px",
                    }}
                >
                    <DiscordBrandIcon sx={{ fontSize: 20 }} />
                </IconButton>
                <IconButton
                    component="a"
                    href="https://x.com/PickTheOdd"
                    target="_blank"
                    rel="noreferrer noopener"
                    sx={{
                        background: theme.palette.grayScale.sixpercent,
                        borderRadius: "8px",
                    }}
                >
                    <XIcon sx={{ fontSize: 20 }} />
                </IconButton>
                <IconButton
                    component="a"
                    href="mailto:support@picktheodds.app"
                    rel="noreferrer noopener"
                    sx={{
                        background: theme.palette.grayScale.sixpercent,
                        borderRadius: "8px",
                    }}
                >
                    <MailOutlineIcon sx={{ fontSize: 20 }} />
                </IconButton>
            </Stack>
        </Stack>
    );
}

export default SocialLinks;