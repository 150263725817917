import React from "react";
import { IceHockeyLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/IceHockeyLiveDataTypeFragment";
import BasicGameDesign from "../BasicGameDesign";

type BasicIceHockeyDesignProps = {
    gameLiveData: IceHockeyLiveDataTypeFragment;
    awayTeamAbbr: string;
    homeTeamAbbr: string;
    isMobile?: boolean;
};

function BasicIceHockeyDesign({ gameLiveData, awayTeamAbbr, homeTeamAbbr, isMobile }: BasicIceHockeyDesignProps) {
    const {
        homeTeamGoalsByPeriod = [0],
        awayTeamGoalsByPeriod = [0],
        period = "",
        gameTimeSeconds = 0,
    } = gameLiveData;

    const homeTeamScore = homeTeamGoalsByPeriod?.reduce((acc, curr) => acc + curr, 0);
    const awayTeamScore = awayTeamGoalsByPeriod?.reduce((acc, curr) => acc + curr, 0);

    return (
        <BasicGameDesign
            period={period}
            gameTimeSeconds={gameTimeSeconds}
            awayTeamAbbr={awayTeamAbbr}
            homeTeamAbbr={homeTeamAbbr}
            awayTeamScore={awayTeamScore}
            homeTeamScore={homeTeamScore}
            isMobile={isMobile}
        />
    );
}

export default BasicIceHockeyDesign;