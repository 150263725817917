import { UpcomingGameStatus, LiveGameStatus } from "@/components/Filters/DateAndTimeFilter.common";
import { defaultExpectedValueFormulaCase, defaultExpectedValueWeights } from "@/components/ExpectedValue/helper";
import { InputIncludeExcludeRequestType } from "@/components/Block/Exclude/common";
import { League } from "../Enums/LeagueEnum";
import { ExpectedValueFormulaCaseEnum, ExpectedValueFormulaEnum } from "../Enums/ExpectedValueFormulaEnum";
import { OddsColumnSortValueEnum } from "../Enums/OddsColumnSortValueEnum";
import { Sports } from "../Enums/SportsEnum";
import { WidthWeightEnum } from "../Enums/WidthWeightEnum";

// When ever adding a field, make sure you update the FilterCountDisplay

export const MAX_TAKE_DEFAULT = 20;
export const DEFAULT_MIN_WIDTH = 1;
export const EV_FORMULA_WEIGHT_DEFAULT = Array(Object.values(ExpectedValueFormulaEnum).length).fill(1);

export type KeyOfPages = "arbitrages" | "middles" | "freebet" | "lowhold" | "expectedvalue" | "odds-screen" | "game";

export type CommonFilterType = {
  startDateTime?: number;
  endDateTime?: number;
  futureHours?: number;
  beforeHours?: number;
  foundSecondsAgo?: number;
  upcomingGameStatus: UpcomingGameStatus;
  liveGameStatus: LiveGameStatus;
  leagues?: League[];
  minRoi?: number;
  maxRoi?: number;
  minAmericanOdds?: number;
  maxAmericanOdds?: number;
  betAmount: number;
  search?: string;
  betMarketInfo?: string[];
  betMarketTypeGroups?: string[];
  region?: string;
  sportsBook?: string[];
  take?: number;
  showAllSportsBook: boolean;
  kellyCriterion: number;
  roundingValues?: number;
  mustIncludeBetSite?: string;
  graphBetSites?: string[];
  displayedMaxOdds?: string;
  displayedMinOdds?: string;
  ignoreDuplicateBetSites?: boolean;
  minMaxWager?: number;

  // search filter
  isInclude?: boolean;
  includeExcludes?: InputIncludeExcludeRequestType[];
  incExcNotApplyAllAdvFltr?: boolean; // not to send to backend

  // ROI Notification
  newDataNotification?: boolean;
  roiNotification?: number;

  // rOIRestrictedBetSites (Not for Free bet and Low hold)
  rOIRestrictedBetSites?: string[];
  rOIRestrictedLeagues?: League[];

  // Arb Filter
  maximumLegs?: number;

  // Medium Filter
  distance?: number;

  // TODO: This should not be optional but we don't have a way for types based on page
  // EV+ filters
  evWeights?: string; // This filter has been changes has force need to create a name
  evFormulaWeight?: number[]; // This filter has been changes has force need to create a name
  expectedValueFormulaCase?: ExpectedValueFormulaCaseEnum;
  minWidth?: number;
  showAllFormulas?: boolean;
  widthWeight?: WidthWeightEnum;

  // Advance Filters
  advanceFilter?: string;

  // odds control filter
  holdColumn?: boolean;
  oddsSort?: boolean;
  holdColumnSort?: OddsColumnSortValueEnum;
  oddWeight?: string[];
  avgColumn: boolean;
  bestColumn: boolean;
  devigAverage?: boolean;
  betValueView?: boolean;
  showEmptyBets?: boolean;

  // Only used for Sports advance filters
  sports?: Sports[];
};

export function hasPageRestrictionLimit(pathKey: KeyOfPages) {
  return pathKey === "arbitrages" || pathKey === "middles" || pathKey === "expectedvalue" || pathKey === "lowhold";
}

export function getDefaultFilterValuesByPathKey(pathKey: KeyOfPages) {
  const defaultValues: CommonFilterType = {
    upcomingGameStatus: UpcomingGameStatus.ALL,
    liveGameStatus: LiveGameStatus.ALL,
    betAmount: 200,
    showAllSportsBook: false,
    kellyCriterion: 0.1,
    avgColumn: true,
    bestColumn: true,
    ignoreDuplicateBetSites: false,
  };

  if (hasPageRestrictionLimit(pathKey)) {
    defaultValues.rOIRestrictedBetSites = [];
    defaultValues.rOIRestrictedLeagues = [];
  }

  if (pathKey === "arbitrages") {
    defaultValues.maximumLegs = 3;
  }

  if (pathKey === "expectedvalue") {
    defaultValues.evWeights = defaultExpectedValueWeights;
    defaultValues.evFormulaWeight = EV_FORMULA_WEIGHT_DEFAULT;
    defaultValues.expectedValueFormulaCase = defaultExpectedValueFormulaCase;
    defaultValues.minWidth = DEFAULT_MIN_WIDTH;
    defaultValues.showAllFormulas = undefined;
    defaultValues.widthWeight = WidthWeightEnum.STATIC;
  }

  if (pathKey === "odds-screen" || pathKey === "game") {
    defaultValues.holdColumn = false;
    defaultValues.holdColumnSort = OddsColumnSortValueEnum.TIME;
    defaultValues.avgColumn = true;
    defaultValues.bestColumn = true;
    defaultValues.devigAverage = false;
    defaultValues.betValueView = false;
    defaultValues.showEmptyBets = false;
  }
  return defaultValues;
}
