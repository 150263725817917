import { League } from "@/components/Common/Enums/LeagueEnum";

export default function formatLeagueName(leagueName: League) {
  if (leagueName === League.EURO_LEAGUE_BB) {
    return "Euroleague BB";
  }
  if (leagueName === League.EUFA_CHAMPIONS) {
    return "EUFA Champions";
  }
  if (leagueName === League.LA_LLGA) {
    return "Spain - La Liga";
  }
  if (leagueName === League.ENGLISH_PREMIER) {
    return "English Premier";
  }
  if (leagueName === League.FRENCH_LIGUE_1) {
    return "French Ligue 1";
  }
  if (leagueName === League.GERMAN_BUNDESLIGA) {
    return "German Bundesliga";
  }
  if (leagueName === League.ITALIAN_SERIA_A) {
    return "Italian Seria A";
  }
  if (leagueName === League.NCAA_BASEBALL) {
    return "College baseball";
  }
  if (leagueName === League.TENNIS) {
    return "Tennis";
  }
  if (leagueName === League.UEFA_EURO) {
    return "UEFA Euro";
  }
  if (leagueName === League.COPA_AMERICA) {
    return "Copa America";
  }
  if (leagueName === League.BRAZIL_SERIE_A) {
    return "Brazil Serie A";
  }
  if (leagueName === League.NBA_SUMMER) {
    return "NBA Summer";
  }
  if (leagueName === League.OLYMPICS_BASKETBALL) {
    return "Olympics Basketball";
  }
  if (leagueName === League.OLYMPICS_BASKETBALL_WOMEN) {
    return "Olympics Basketball Women";
  }
  if (leagueName === League.OLYMPICS_SOCCER) {
    return "Olympics Soccer";
  }
  if (leagueName === League.OLYMPICS_SOCCER_WOMEN) {
    return "Olympics Soccer Women";
  }
  if (leagueName === League.UEFA_EUROPA) {
    return "UEFA Europa";
  }
  if (leagueName === League.CBA) {
    return "China Basketball";
  }
  return leagueName;
}
