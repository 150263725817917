import React from "react";
import { BasketballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BasketballLiveDataTypeFragment";
import BasicGameDesign from "../BasicGameDesign";


type BasicBasketBallDesignProps = {
    gameLiveData: BasketballLiveDataTypeFragment;
    awayTeamAbbr: string;
    homeTeamAbbr: string;
    isMobile?: boolean;
};

function BasicBasketBallDesign({ gameLiveData, awayTeamAbbr, homeTeamAbbr, isMobile }: BasicBasketBallDesignProps) {
    const {
        homeTeamTotalPointsByQuarter = [0],
        awayTeamTotalPointsByQuarter = [0],
        period = "",
        gameTimeSeconds = 0,
    } = gameLiveData;

    const homeTeamScore = homeTeamTotalPointsByQuarter?.reduce((acc, curr) => acc + curr, 0);
    const awayTeamScore = awayTeamTotalPointsByQuarter?.reduce((acc, curr) => acc + curr, 0);

    return (
        <BasicGameDesign
            period={period}
            gameTimeSeconds={gameTimeSeconds}
            awayTeamAbbr={awayTeamAbbr}
            homeTeamAbbr={homeTeamAbbr}
            awayTeamScore={awayTeamScore}
            homeTeamScore={homeTeamScore}
            isMobile={isMobile}
        />
    );
}

export default BasicBasketBallDesign;