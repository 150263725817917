import React from "react";
import { TennisLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/TennisLiveDataTypeFragment";
import BasicGameDesign from "../BasicGameDesign";

type BasicTennisDesignProps = {
    gameLiveData: TennisLiveDataTypeFragment;
    homeTeamName: string;
    awayTeamName: string;
    isMobile?: boolean;
};

function BasicTennisDesign({ gameLiveData, homeTeamName, awayTeamName, isMobile }: BasicTennisDesignProps) {
    const {
        player1Points = 0,
        player2Points = 0,
        player1GamesBySet = [],
    } = gameLiveData;

    const player1Score = player1Points;
    const player2Score = player2Points;
    const currentSetIndex = Math.max((player1GamesBySet ?? []).length - 1, 0);
    const period = `S${currentSetIndex + 1}`;

    return (
        <BasicGameDesign
            period={period}
            awayTeamAbbr={awayTeamName}
            homeTeamAbbr={homeTeamName}
            awayTeamScore={player1Score}
            homeTeamScore={player2Score}
            isMobile={isMobile}
        />
    );
}

export default BasicTennisDesign;