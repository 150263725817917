import { PaletteType } from "@/theme/common";
import { Box, useTheme } from "@mui/material";
import Image from "next/image";
import React from "react";

interface LogoProgressSpinnerProps {
  size?: string | number;
}

function LogoProgressSpinner({ size = 54 }: LogoProgressSpinnerProps) {
  const theme = useTheme();
  const primaryColor = theme.palette.text.primary;
  const secondaryColor = theme.palette.primary.main;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >

      <Box
        sx={{
          position: 'absolute',
          width: `calc(${size}px - 10px)`,
          height: `calc(${size}px - 10px)`,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: secondaryColor,
          borderRadius: '50%',
          overflow: 'hidden',
          zIndex: 2
        }}
      >
        <Image
          src={theme.palette.mode === PaletteType.Dark ? `/P-Loader.gif` : `/P-logo-circle-light.png`}
          alt="Pick The Odds"
          fill
          style={{ objectFit: 'contain' }}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
      </Box>
      <Box
        sx={{
          transform: 'translate(-50%, -50%)',
          height: size,
          width: size,
          boxSizing: 'border-box',
          background: `conic-gradient(
            from 90deg at 50% 50%,
            rgba(39, 174, 96, 0) 0deg,
            rgba(31, 144, 255, 0) 0.04deg,
            ${primaryColor} 360deg
          )`,
          borderRadius: '50%',
          animation: 'rotate 1s infinite linear',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            height: `calc(${size}px - 16px)`,
            width: `calc(${size}px - 16px)`,
            background: 'transparent',
            borderRadius: '50%',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            height: `calc(${size}px - 48px)`,
            width: `calc(${size}px - 48px)`,
            background: primaryColor,
            borderRadius: '50%',
          },
          '@keyframes rotate': {
            '0%': {
              transform: 'rotate(0deg)',
            },
            '100%': {
              transform: 'rotate(360deg)',
            },
          },
        }}
      />
    </Box>
  );
}

export default LogoProgressSpinner;