import React from "react";
import { SoccerLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/SoccerLiveDataTypeFragment";
import BasicGameDesign from "../BasicGameDesign";

type BasicSoccerDesignProps = {
    gameLiveData: SoccerLiveDataTypeFragment;
    awayTeamAbbr: string;
    homeTeamAbbr: string;
    isMobile?: boolean;
};

function BasicSoccerDesign({ gameLiveData, awayTeamAbbr, homeTeamAbbr, isMobile }: BasicSoccerDesignProps) {
    const {
        homeTeamScoreByHalf = [0],
        awayTeamScoreByHalf = [0],
        period = "00",
        gameTimeSeconds = 0,
    } = gameLiveData;

    const homeTeamScore = homeTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);

    return (
        <BasicGameDesign
            period={period}
            gameTimeSeconds={gameTimeSeconds}
            awayTeamAbbr={awayTeamAbbr}
            homeTeamAbbr={homeTeamAbbr}
            awayTeamScore={awayTeamScore}
            homeTeamScore={homeTeamScore}
            isMobile={isMobile}
        />
    );
}

export default BasicSoccerDesign;