'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useBuildId, usePrevious } from '@/hooks/useBuildId';

export default function BuildIdChecker() {
    const { buildId } = useBuildId();
    const prevBuildId = usePrevious(buildId);
    const router = useRouter();

    useEffect(() => {
        if (prevBuildId && buildId && prevBuildId !== buildId) {
            router.refresh();
        }
    }, [buildId, prevBuildId, router]);

    return null;
}