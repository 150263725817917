import React from "react";
import { Box, Stack, useTheme } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { BasketballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BasketballLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { formatPeriod } from "../Utils";
import { LiveDataDesign } from "../DesignEnum";

type DefaultBasketballDesignProps = {
    gameLiveData: BasketballLiveDataTypeFragment;
    showAdditionalData?: boolean;
};

function DefaultBasketballDesign({ gameLiveData, showAdditionalData }: DefaultBasketballDesignProps) {
    const theme = useTheme();
    const {
        homeTeamTotalPointsByQuarter = [0],
        awayTeamTotalPointsByQuarter = [0],
        period = "",
        gameTimeSeconds = 0,
        homeTeamHasPossession = false,
        additionalData = "",
    } = gameLiveData;

    const homeTeamScore = homeTeamTotalPointsByQuarter?.reduce((acc, curr) => acc + curr, 0)?.toString();
    const awayTeamScore = awayTeamTotalPointsByQuarter?.reduce((acc, curr) => acc + curr, 0)?.toString();
    const formattedPeriod = formatPeriod(period);
    const formattedGameTime = formatSeconds(gameTimeSeconds);

    return (
        <Box>
            <Stack direction="row" spacing={1} alignItems="start">
                <Stack direction="column" spacing={0} alignItems="start">
                    <StyledTypography variant="body2" sx={{ fontWeight: "bold" }}>
                        {formattedPeriod} {formattedGameTime}
                    </StyledTypography>
                    <Stack direction="row" spacing={1} alignItems="start">
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ width: "fit-content", minWidth: "110px" }}>
                            <Stack
                                direction="column"
                                spacing={0}
                                sx={{ borderRight: `0.5px solid ${theme.palette.border.primary}`, pr: 1, flexShrink: 0 }}
                            >
                                <Stack direction="row" spacing={1} alignItems="right" justifyContent="space-between">
                                    {awayTeamTotalPointsByQuarter?.map((points, index) => (
                                        <StyledTypography color="text.primary" variant="body2" key={`away-set-${index}`}>
                                            {points}
                                        </StyledTypography>
                                    ))}
                                    <StyledTypography variant="body2" sx={{ ml: 1 }}>
                                        -
                                    </StyledTypography>
                                </Stack>

                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                    {homeTeamTotalPointsByQuarter?.map((points, index) => (
                                        <StyledTypography variant="body2" key={`home-set-${index}`}>
                                            {points}
                                        </StyledTypography>
                                    ))}
                                    <StyledTypography variant="body2" sx={{ ml: 1 }}>
                                        -
                                    </StyledTypography>
                                </Stack>
                            </Stack>

                            <Stack direction="column" spacing={0} alignItems="start" sx={{ flexGrow: 1 }}>
                                <ScoreIndicatorLiveData score={awayTeamScore} isServing={!homeTeamHasPossession} design={LiveDataDesign.DEFAULT} />
                                <ScoreIndicatorLiveData score={homeTeamScore} isServing={homeTeamHasPossession} design={LiveDataDesign.DEFAULT} />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                {
                    showAdditionalData && (
                        <Box>
                            <StyledTypography
                                lineClamp={3}
                                mt={0.5}
                                color="text.secondary"
                                variant="caption"
                                sx={{
                                    lineHeight: "1em",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    whiteSpace: "normal",
                                }}
                            >
                                {additionalData}
                            </StyledTypography>
                        </Box>
                    )}
            </Stack>
        </Box>
    );
};

export default DefaultBasketballDesign;
