import React, { useState } from "react";
import { Box, Stack, IconButton, useTheme } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { IceHockeyLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/IceHockeyLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import SensorsIcon from "@mui/icons-material/Sensors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslations } from "next-intl";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";

type DefaultIceHockeyDesignProps = {
    gameLiveData: IceHockeyLiveDataTypeFragment;
    elapsedTime: string;
    isFuture: boolean;
};

const views = ["default", "penaltyCounts", "penaltyMinutes", "penaltyShots"] as const;
type ViewType = typeof views[number];

const formatPeriod = (period: string | undefined) => {
    if (!period || period === "Halftime") return "";

    const periodNumber = parseInt(period.replace("P", ""), 10);

    let suffix = "th";
    if (periodNumber % 10 === 1 && periodNumber % 100 !== 11) {
        suffix = "st";
    } else if (periodNumber % 10 === 2 && periodNumber % 100 !== 12) {
        suffix = "nd";
    } else if (periodNumber % 10 === 3 && periodNumber % 100 !== 13) {
        suffix = "rd";
    }
    return `${periodNumber}${suffix}`;
};



function DetailedIceHockeyDesign({ gameLiveData, elapsedTime, isFuture }: DefaultIceHockeyDesignProps) {
    const theme = useTheme();
    const [currentView, setCurrentView] = useState<ViewType>("default");
    const t = useTranslations("liveData");
    const {
        homeTeamGoalsByPeriod = [0],
        awayTeamGoalsByPeriod = [0],
        period = "",
        gameTimeSeconds = 0,
        homeTeamHasPossession = false,
        additionalData = "",
        awayTeamPenalityMinuteByPeriod = [0],
        homeTeamPenalityMinuteByPeriod = [0],
        awayTeamPenalityCountByPeriod = [0],
        homeTeamPenalityCountByPeriod = [0],
        awayTeamPenalityShotByPeriod = [0],
        homeTeamPenalityShotByPeriod = [0],
    } = gameLiveData;


    const homeTeamScore = homeTeamGoalsByPeriod?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamGoalsByPeriod?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const formattedPeriod = formatPeriod(period);
    const formattedGameTime = formatSeconds(gameTimeSeconds);

    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next" ? (currentIndex + 1) % views.length : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };

    const getViewTitle = () => {
        switch (currentView) {
            case "default":
                return t("scores");
            case "penaltyCounts":
                return t("panelty_counts");
            case "penaltyMinutes":
                return t("penalty_minutes");
            case "penaltyShots":
                return t("penalty_shots");
            default:
                return "";
        }
    };

    const getTotal = (data: number[] | null) => (data ?? []).reduce((a, b) => a + b, 0);

    const getViewData = () => {
        switch (currentView) {
            case "default":
                return {
                    awayData: awayTeamGoalsByPeriod,
                    homeData: homeTeamGoalsByPeriod,
                    awayScore: awayTeamScore,
                    homeScore: homeTeamScore,
                };
            case "penaltyCounts":
                return {
                    awayData: awayTeamPenalityCountByPeriod,
                    homeData: homeTeamPenalityCountByPeriod,
                    awayScore: getTotal(awayTeamPenalityCountByPeriod),
                    homeScore: getTotal(homeTeamPenalityCountByPeriod),
                };
            case "penaltyMinutes":
                return {
                    awayData: awayTeamPenalityMinuteByPeriod,
                    homeData: homeTeamPenalityMinuteByPeriod,
                    awayScore: getTotal(awayTeamPenalityMinuteByPeriod),
                    homeScore: getTotal(homeTeamPenalityMinuteByPeriod),
                };
            case "penaltyShots":
                return {
                    awayData: awayTeamPenalityShotByPeriod,
                    homeData: homeTeamPenalityShotByPeriod,
                    awayScore: getTotal(awayTeamPenalityShotByPeriod),
                    homeScore: getTotal(homeTeamPenalityShotByPeriod),
                };
            default:
                return {
                    awayData: [],
                    homeData: [],
                    awayScore: 0,
                    homeScore: 0,
                };
        }
    };

    const { awayData, homeData, awayScore, homeScore } = getViewData();

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                    <StyledTypography variant="caption" color="text.primary">
                        {formattedPeriod} {formattedGameTime}
                    </StyledTypography>
                </Stack>
                <Stack alignItems="center" justifyContent="center" direction="row" gap={0.5} sx={{ flexGrow: 1 }}>
                    <StyledTypography variant="body2">
                        Live Data
                    </StyledTypography>
                    <SensorsIcon fontSize="small" color="error" />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={1} alignItems="start" mt={0.5}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack width={150}>
                        <StyledTypography
                            color="text.secondary"
                            variant="caption"
                        >
                            {getViewTitle()}
                        </StyledTypography>
                    </Stack>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="start">
                    <Stack direction="row" spacing={1} alignItems="center" width={145}>
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                borderRight: `0.5px solid ${theme.palette.border.primary}`,
                                pr: 1,
                                flexShrink: 0,
                            }}
                        >
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {["1", "2", "3"].map((header, index) => (
                                    <StyledTypography
                                        color="text.secondary"
                                        variant="body2"
                                        key={`header-${index}`}
                                        sx={{ width: 18, textAlign: 'center' }}
                                    >
                                        {header}
                                    </StyledTypography>
                                ))}
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {Array(3).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        color="text.primary"
                                        variant="body2"
                                        key={`away-data-${index}`}
                                        sx={{ width: 18, textAlign: 'center' }}
                                    >
                                        {awayData && awayData[index] !== undefined ? awayData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {Array(3).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        variant="body2"
                                        key={`home-data-${index}`}
                                        sx={{ width: 18, textAlign: 'center' }}
                                    >
                                        {homeData && homeData[index] !== undefined ? homeData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        <Stack direction="column" spacing={0} mt={1} alignItems="start" sx={{ flexGrow: 1 }}>
                            <StyledTypography
                                color="text.secondary"
                                variant="body2"
                                sx={{ width: 18, textAlign: 'center', ml: -0.5 }}
                            >
                                T
                            </StyledTypography>
                            <ScoreIndicatorLiveData score={awayScore?.toString()} isServing={!homeTeamHasPossession} design={LiveDataDesign.DETAILED} view={currentView} />
                            <ScoreIndicatorLiveData score={homeScore?.toString()} isServing={homeTeamHasPossession} design={LiveDataDesign.DETAILED} view={currentView} />
                        </Stack>
                    </Stack>
                    <Box width={135}>
                        <StyledTypography
                            lineClamp={4}
                            mt={0.5}
                            color="text.secondary"
                            variant="caption"
                            sx={{
                                lineHeight: "1.2em",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                whiteSpace: "normal",
                            }}
                        >
                            {additionalData}
                        </StyledTypography>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
}

export default DetailedIceHockeyDesign;