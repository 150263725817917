import React, { useState } from "react";
import { Box, Stack, IconButton, useTheme } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { FootballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/FootballLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import SensorsIcon from "@mui/icons-material/Sensors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslations } from "next-intl";
import { determineYardsToGo, formatPeriod, isInRedZone } from "../Utils";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";

type DefaultFootballDesignProps = {
    gameLiveData: FootballLiveDataTypeFragment;
    homeTeamAbbr?: string;
    awayTeamAbbr?: string;
};

const views = ["default", "touchdowns", "fieldGoals", "safeties", "turnovers", "drives", "passes", "coachChallenges", "timeouts"] as const;
type ViewType = typeof views[number];

function DetailedFootballDesign({ gameLiveData, homeTeamAbbr, awayTeamAbbr }: DefaultFootballDesignProps) {
    const theme = useTheme();
    const t = useTranslations("liveData");
    const [currentView, setCurrentView] = useState<ViewType>("default");
    const {
        awayTeamTotalPoints = [0],
        homeTeamTotalPoints = [0],
        homeTeamHasPossession = false,
        period = "00",
        gameTimeSeconds = 0,
        activeDown = "00",
        yardsToNextDown = "00",
        yardsDistance = "00",
        additionalData = "",
        awayTeamTouchdowns = [0],
        homeTeamTouchdowns = [0],
        awayTeamFieldGoals = [0],
        homeTeamFieldGoals = [0],
        awayTeamSafeties = [0],
        homeTeamSafeties = [0],
        awayTeamTurnovers = [0],
        homeTeamTurnovers = [0],
        awayTeamDrives = [0],
        homeTeamDrives = [0],
        awayTeamPasses = [0],
        homeTeamPasses = [0],
        awayTeamCoachChallenges = [0],
        homeTeamCoachChallenges = [0],
        awayTeamTimeouts = [0],
        homeTeamTimeouts = [0],
    } = gameLiveData;

    const homeTeamScore = homeTeamTotalPoints?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamTotalPoints?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const isHomeTeamPossession = homeTeamHasPossession;
    const formattedYardsToGo = determineYardsToGo(yardsDistance?.toString() || "", homeTeamAbbr, awayTeamAbbr, isHomeTeamPossession);
    const redZoneStatus = isInRedZone(yardsDistance?.toString() || "");
    const getTotal = (data: number[] | null) => (data ?? []).reduce((a, b) => a + b, 0);

    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next" ? (currentIndex + 1) % views.length : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };

    const getViewTitle = () => {
        switch (currentView) {
            case "default":
                return t("scores_by_quarter");
            case "touchdowns":
                return t("touchdowns");
            case "fieldGoals":
                return t("field_goals");
            case "safeties":
                return t("safeties");
            case "turnovers":
                return t("turnovers");
            case "drives":
                return t("drives");
            case "passes":
                return t("passes");
            case "coachChallenges":
                return t("coaches_challenges");
            case "timeouts":
                return t("timeouts");
            default:
                return "";
        }
    };

    const getViewData = () => {
        switch (currentView) {
            case "default":
                return {
                    awayData: awayTeamTotalPoints,
                    homeData: homeTeamTotalPoints,
                    awayScore: awayTeamScore,
                    homeScore: homeTeamScore,
                };
            case "touchdowns":
                return {
                    awayData: awayTeamTouchdowns,
                    homeData: homeTeamTouchdowns,
                    awayScore: getTotal(awayTeamTouchdowns),
                    homeScore: getTotal(homeTeamTouchdowns),
                };
            case "fieldGoals":
                return {
                    awayData: awayTeamFieldGoals,
                    homeData: homeTeamFieldGoals,
                    awayScore: getTotal(awayTeamFieldGoals),
                    homeScore: getTotal(homeTeamFieldGoals),
                };
            case "safeties":
                return {
                    awayData: awayTeamSafeties,
                    homeData: homeTeamSafeties,
                    awayScore: getTotal(awayTeamSafeties),
                    homeScore: getTotal(homeTeamSafeties),
                };
            case "turnovers":
                return {
                    awayData: awayTeamTurnovers,
                    homeData: homeTeamTurnovers,
                    awayScore: getTotal(awayTeamTurnovers),
                    homeScore: getTotal(homeTeamTurnovers),
                };
            case "drives":
                return {
                    awayData: awayTeamDrives,
                    homeData: homeTeamDrives,
                    awayScore: getTotal(awayTeamDrives),
                    homeScore: getTotal(homeTeamDrives),
                };
            case "passes":
                return {
                    awayData: awayTeamPasses,
                    homeData: homeTeamPasses,
                    awayScore: getTotal(awayTeamPasses),
                    homeScore: getTotal(homeTeamPasses),
                };
            case "coachChallenges":
                return {
                    awayData: awayTeamCoachChallenges,
                    homeData: homeTeamCoachChallenges,
                    awayScore: getTotal(awayTeamCoachChallenges),
                    homeScore: getTotal(homeTeamCoachChallenges),
                };
            case "timeouts":
                return {
                    awayData: awayTeamTimeouts,
                    homeData: homeTeamTimeouts,
                    awayScore: getTotal(awayTeamTimeouts),
                    homeScore: getTotal(homeTeamTimeouts),
                };
            default:
                return {
                    awayData: [],
                    homeData: [],
                    awayScore: 0,
                    homeScore: 0,
                };
        }
    };

    const { awayData, homeData, awayScore, homeScore } = getViewData();

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                    <StyledTypography variant="caption" color="text.primary">
                        {formatPeriod(period)} {formatSeconds(gameTimeSeconds)}
                    </StyledTypography>
                </Stack>
                <Stack alignItems="center" justifyContent="center" direction="row" gap={0.5} sx={{ flexGrow: 1 }}>
                    <StyledTypography variant="body2">
                        Live Data
                    </StyledTypography>
                    <SensorsIcon fontSize="small" color="error" />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={1} alignItems="start" mt={0.5}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack width={150}>
                        <StyledTypography
                            color="text.secondary"
                            variant="caption"
                        >
                            {getViewTitle()}
                        </StyledTypography>
                    </Stack>
                    <Stack direction="row">
                        <StyledTypography variant="caption" color="text.secondary" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                            {activeDown} & {yardsToNextDown} on {formattedYardsToGo}
                        </StyledTypography>
                    </Stack>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="start">
                    <Stack direction="row" spacing={1} alignItems="center" width={145}>
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                borderRight: `0.5px solid ${theme.palette.border.primary}`,
                                pr: 1,
                                flexShrink: 0,
                            }}
                        >
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {["1", "2", "3", "4"].map((header, index) => (
                                    <StyledTypography
                                        color="text.secondary"
                                        variant="body2"
                                        key={`header-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {header}
                                    </StyledTypography>
                                ))}
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {Array(4).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        color="text.primary"
                                        variant="body2"
                                        key={`away-data-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {awayData && awayData[index] !== undefined ? awayData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {Array(4).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        variant="body2"
                                        key={`home-data-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {homeData && homeData[index] !== undefined ? homeData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        <Stack direction="column" spacing={0} mt={1} alignItems="start" sx={{ flexGrow: 1 }}>
                            <StyledTypography
                                color="text.secondary"
                                variant="body2"
                                sx={{ width: 16, textAlign: 'center', ml: -0.5 }}
                            >
                                T
                            </StyledTypography>
                            <ScoreIndicatorLiveData score={awayScore?.toString()} isServing={!isHomeTeamPossession} design={LiveDataDesign.DETAILED} isInRedZone={!isHomeTeamPossession && redZoneStatus} view={currentView} />
                            <ScoreIndicatorLiveData score={homeScore?.toString()} isServing={isHomeTeamPossession} design={LiveDataDesign.DETAILED} isInRedZone={isHomeTeamPossession && redZoneStatus} view={currentView} />
                        </Stack>
                    </Stack>
                    <Box width={135}>
                        <StyledTypography
                            lineClamp={4}
                            mt={0.5}
                            color="text.secondary"
                            variant="caption"
                            sx={{
                                lineHeight: "1em",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                whiteSpace: "normal",
                            }}
                        >
                            {additionalData}
                        </StyledTypography>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};

export default DetailedFootballDesign;