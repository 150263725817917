import { League } from "@/components/Common/Enums/LeagueEnum";
import { Sports } from "@/components/Common/Enums/SportsEnum";
import { useAppSelector } from "@/store/store";
import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import useGetCurrentlyFromLiveData from "@/hooks/useGetCurrentlyFromLiveData";
import { useTranslations } from "next-intl";
import TennisLiveData from "./TennisLiveData/TennisLiveData";
import BaseBallLiveData from "./BaseBallLiveData/BaseBallLiveData";
import SoccerLiveData from "./SoccerLiveData/SoccerLiveData";
import FootballLiveData from "./FootBallLiveData/FootBallLiveData";
import IceHockeyLiveData from "./IceHockeyLiveData/IceHockeyLiveData";
import BasketballLiveData from "./BasketBallLiveData/BasketBallLiveData";
import { LiveDataDesign } from "./DesignEnum";
import { BetMarketTypeEnum } from "../Common/Enums/BetMarketTypeEnum";

type CurrentlySportLiveDataProps = {
  gameId: string;
  league: League;
  betMarketType: BetMarketTypeEnum;
  teamId?: number;
  playerId?: number;
};

function CurrentlySportLiveData(props: CurrentlySportLiveDataProps) {
  const t = useTranslations("common");
  const theme = useTheme();
  const currently = useGetCurrentlyFromLiveData(props.gameId, props.league, props.betMarketType, props.teamId, props.playerId);
  if (currently === null || currently === undefined) return null;
  return (
    <Stack direction="row" alignItems="end" sx={{ lineHeight: "12px !important" }}>
      <Typography variant="caption" color="text.secondary" sx={{ lineHeight: "12px !important", textTransform: "uppercase" }}>
        {t("currently")}:&nbsp;&nbsp;
      </Typography>
      <Typography variant="number" color="text.primary" sx={{ fontSize: theme.typography.caption.fontSize, fontWeight: 400 }}>
        {currently}
      </Typography>
    </Stack>
  );
}

type SportLiveDataProps = {
  gameId: string;
  leagueId: League;
  startTime?: number;
  design?: LiveDataDesign;
  betMarketType?: string | undefined;
  conditionTeamId?: number | undefined;
  conditionPlayerId?: number | undefined;
  showAdditionalData?: boolean;
  isMobile?: boolean;
};

function SportLiveData({
  gameId,
  leagueId,
  startTime,
  design,
  betMarketType,
  conditionTeamId,
  conditionPlayerId,
  showAdditionalData = true,
  isMobile
}: SportLiveDataProps) {
  const leaguesList = useAppSelector((state) => state.constantsReducer.leagues);
  const sportId = leaguesList?.find((lg: { id: League }) => lg.id === leagueId)?.sportId;

  const gameLiveDataList = useAppSelector((state) => state.gameLiveDataReducer);
  const gameData = useAppSelector((state) => state.gameDataCacheReducer.gameData[gameId]);
  const homeTeamAbbreavation = gameData?.homeTeam?.abbreviations[0];
  const awayTeamAbbreavation = gameData?.awayTeam?.abbreviations[0];
  const homeTeamName = gameData?.homeTeam?.name;
  const awayTeamName = gameData?.awayTeam?.name;

  const gameLiveData = gameLiveDataList[gameId];

  // Define sportProps once
  const sportProps = {
    gameLiveData,
    startTime,
    design,
    homeTeamAbbr: homeTeamAbbreavation,
    awayTeamAbbr: awayTeamAbbreavation,
    showAdditionalData,
    homeTeamName,
    awayTeamName,
    isMobile
  };

  // Create a mapping of sportId to components
  const sportComponentMap: Record<Sports, React.ElementType | undefined> = {
    [Sports.FOOTBALL]: FootballLiveData,
    [Sports.SOCCER]: SoccerLiveData,
    [Sports.BASEBALL]: BaseBallLiveData,
    [Sports.TENNIS]: TennisLiveData,
    [Sports.ICE_HOCKEY]: IceHockeyLiveData,
    [Sports.BASKETBALL]: BasketballLiveData,
  };

  const SportComponent = sportComponentMap[sportId as Sports];

  if (!SportComponent) {
    return null;
  }

  return (
    <>
      <SportComponent {...sportProps} />
      {betMarketType?.includes("PLAYER_PROP") && (
        <CurrentlySportLiveData
          gameId={gameId}
          league={leagueId}
          betMarketType={betMarketType as BetMarketTypeEnum}
          teamId={conditionTeamId}
          playerId={conditionPlayerId}
        />
      )}
    </>
  );
}

export default SportLiveData;
