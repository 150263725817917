import React from "react";
import { FootballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/FootballLiveDataTypeFragment";
import BasicGameDesign from "../BasicGameDesign";

type BasicFootBallDesignProps = {
    gameLiveData: FootballLiveDataTypeFragment;
    awayTeamAbbr: string;
    homeTeamAbbr: string;
    isMobile?: boolean;
};

function BasicFootBallDesign({ gameLiveData, awayTeamAbbr, homeTeamAbbr, isMobile }: BasicFootBallDesignProps) {
    const {
        awayTeamTotalPoints = [0],
        homeTeamTotalPoints = [0],
        period = "00",
        gameTimeSeconds = 0,
    } = gameLiveData;

    const homeTeamScore = homeTeamTotalPoints?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamTotalPoints?.reduce((acc, curr) => acc + (curr ?? 0), 0);

    return (
        <BasicGameDesign
            period={period}
            gameTimeSeconds={gameTimeSeconds}
            awayTeamAbbr={awayTeamAbbr}
            homeTeamAbbr={homeTeamAbbr}
            awayTeamScore={awayTeamScore}
            homeTeamScore={homeTeamScore}
            isMobile={isMobile}
        />
    );
}

export default BasicFootBallDesign;