import React, { useState } from "react";
import { Box, Stack, IconButton, useTheme } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { SoccerLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/SoccerLiveDataTypeFragment";
import SensorsIcon from "@mui/icons-material/Sensors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import formatSeconds from "@/utis/formatGameSeconds";
import { useTranslations } from "next-intl";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";
import { formatPeriod } from "../Utils";

type DefaultSoccerDesignProps = {
    gameLiveData: SoccerLiveDataTypeFragment;
};

const views = ["default", "corners", "yellowCards", "redCards", "fouls", "freeKicks", "goalKicks", "offsides"] as const;

type ViewType = typeof views[number];

function DetailedSoccerDesign({ gameLiveData }: DefaultSoccerDesignProps) {
    const theme = useTheme();
    const t = useTranslations("liveData");
    const [currentView, setCurrentView] = useState<ViewType>("default");

    const {
        homeTeamScoreByHalf = [0],
        awayTeamScoreByHalf = [0],
        homeTeamHasPossession = false,
        gameTimeSeconds = 0,
        additionalData,
        homeTeamCornersByHalf = [0],
        awayTeamCornersByHalf = [0],
        homeTeamYellowCardsByHalf = [0],
        awayTeamYellowCardsByHalf = [0],
        homeTeamRedCardsByHalf = [0],
        awayTeamRedCardsByHalf = [0],
        homeTeamFoulsByHalf = [0],
        awayTeamFoulsByHalf = [0],
        homeTeamFreeKicksByHalf = [0],
        awayTeamFreeKicksByHalf = [0],
        homeTeamGoalKicksByHalf = [0],
        awayTeamGoalKicksByHalf = [0],
        homeTeamOffsidesByHalf = [0],
        awayTeamOffsidesByHalf = [0],
        period,
    } = gameLiveData;


    const homeTeamScore = homeTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const isHomeTeamPossession = homeTeamHasPossession;

    const handleViewChange = (direction: "next" | "prev") => {
        const currentIndex = views.indexOf(currentView);
        const newIndex = direction === "next" ? (currentIndex + 1) % views.length : (currentIndex - 1 + views.length) % views.length;
        setCurrentView(views[newIndex]);
    };

    const getViewTitle = () => {
        switch (currentView) {
            case "default":
                return t("scores_by_half");
            case "corners":
                return t("corners");
            case "yellowCards":
                return t("yellow_cards");
            case "redCards":
                return t("red_cards");
            case "fouls":
                return t("fouls");
            case "freeKicks":
                return t("free_kicks");
            case "goalKicks":
                return t("goal_kicks");
            case "offsides":
                return t("offsides");
            default:
                return "";
        }
    };

    const getTotal = (data: number[] | null) => (data ?? []).reduce((a, b) => a + b, 0);

    const getViewData = () => {
        switch (currentView) {
            case "default":
                return {
                    awayData: awayTeamScoreByHalf,
                    homeData: homeTeamScoreByHalf,
                    awayScore: awayTeamScore,
                    homeScore: homeTeamScore,
                };
            case "corners":
                return {
                    awayData: awayTeamCornersByHalf,
                    homeData: homeTeamCornersByHalf,
                    awayScore: getTotal(awayTeamCornersByHalf),
                    homeScore: getTotal(homeTeamCornersByHalf),
                };
            case "yellowCards":
                return {
                    awayData: awayTeamYellowCardsByHalf,
                    homeData: homeTeamYellowCardsByHalf,
                    awayScore: getTotal(awayTeamYellowCardsByHalf),
                    homeScore: getTotal(homeTeamYellowCardsByHalf),
                };
            case "redCards":
                return {
                    awayData: awayTeamRedCardsByHalf,
                    homeData: homeTeamRedCardsByHalf,
                    awayScore: getTotal(awayTeamRedCardsByHalf),
                    homeScore: getTotal(homeTeamRedCardsByHalf),
                };
            case "fouls":
                return {
                    awayData: awayTeamFoulsByHalf,
                    homeData: homeTeamFoulsByHalf,
                    awayScore: getTotal(awayTeamFoulsByHalf),
                    homeScore: getTotal(homeTeamFoulsByHalf),
                };
            case "freeKicks":
                return {
                    awayData: awayTeamFreeKicksByHalf,
                    homeData: homeTeamFreeKicksByHalf,
                    awayScore: getTotal(awayTeamFreeKicksByHalf),
                    homeScore: getTotal(homeTeamFreeKicksByHalf),
                };
            case "goalKicks":
                return {
                    awayData: awayTeamGoalKicksByHalf,
                    homeData: homeTeamGoalKicksByHalf,
                    awayScore: getTotal(awayTeamGoalKicksByHalf),
                    homeScore: getTotal(homeTeamGoalKicksByHalf),
                };
            case "offsides":
                return {
                    awayData: awayTeamOffsidesByHalf,
                    homeData: homeTeamOffsidesByHalf,
                    awayScore: getTotal(awayTeamOffsidesByHalf),
                    homeScore: getTotal(homeTeamOffsidesByHalf),
                };
            default:
                return {
                    awayData: [],
                    homeData: [],
                    awayScore: 0,
                    homeScore: 0,
                };
        }
    };


    const { awayData, homeData, awayScore, homeScore } = getViewData();

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                    <StyledTypography variant="caption" color="text.primary">
                        {formatPeriod(period)} {formatSeconds(gameTimeSeconds)}
                    </StyledTypography>
                </Stack>
                <Stack alignItems="center" justifyContent="center" direction="row" gap={0.5} sx={{ flexGrow: 1 }}>
                    <StyledTypography variant="body2">
                        Live Data
                    </StyledTypography>
                    <SensorsIcon fontSize="small" color="error" />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <IconButton onClick={() => handleViewChange("prev")} sx={{ py: 0 }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange("next")} sx={{ py: 0 }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={1} alignItems="start" mt={0.5}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack width={150}>
                        <StyledTypography
                            color="text.secondary"
                            variant="caption"
                        >
                            {getViewTitle()}
                        </StyledTypography>
                    </Stack>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="start">
                    <Stack direction="row" spacing={1} alignItems="center" width={145}>
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                borderRight: `0.5px solid ${theme.palette.border.primary}`,
                                pr: 1,
                                flexShrink: 0,
                            }}
                        >
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {["1", "2"].map((header, index) => (
                                    <StyledTypography
                                        color="text.secondary"
                                        variant="body2"
                                        key={`header-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {header}
                                    </StyledTypography>
                                ))}
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {Array(2).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        color="text.primary"
                                        variant="body2"
                                        key={`away-data-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {awayData && awayData[index] !== undefined ? awayData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {Array(2).fill(undefined).map((_, index) => (
                                    <StyledTypography
                                        variant="body2"
                                        key={`home-data-${index}`}
                                        sx={{ width: 16, textAlign: 'center' }}
                                    >
                                        {homeData && homeData[index] !== undefined ? homeData[index] : "-"}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        <Stack direction="column" spacing={0} mt={1} alignItems="start" sx={{ flexGrow: 1 }}>
                            <StyledTypography
                                color="text.secondary"
                                variant="body2"
                                sx={{ width: 16, textAlign: 'center', ml: -0.5 }}
                            >
                                T
                            </StyledTypography>
                            <ScoreIndicatorLiveData score={awayScore?.toString()} isServing={!isHomeTeamPossession} design={LiveDataDesign.DETAILED} view={currentView} />
                            <ScoreIndicatorLiveData score={homeScore?.toString()} isServing={isHomeTeamPossession} design={LiveDataDesign.DETAILED} view={currentView} />
                        </Stack>
                    </Stack>
                    <Box width={135}>
                        <StyledTypography
                            lineClamp={4}
                            color="text.secondary"
                            mt={0.5}
                            variant="caption"
                            sx={{
                                lineHeight: "1em",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                whiteSpace: "normal",
                            }}
                        >
                            {additionalData}
                        </StyledTypography>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};

export default DetailedSoccerDesign;