import { League } from "@/components/Common/Enums/LeagueEnum";
import { Sports } from "@/components/Common/Enums/SportsEnum";

export default function getSportsOfLeague(league: League) {
  switch (league) {
    case League.NCAAF:
    case League.NFL:
    case League.CFL:
      return Sports.FOOTBALL;
    case League.NHL:
      return Sports.ICE_HOCKEY;
    case League.NCAAB:
    case League.NBA:
    case League.WNBA:
    case League.EURO_LEAGUE_BB:
    case League.NCAA_BASEBALL:
    case League.NBA_SUMMER:
    case League.OLYMPICS_BASKETBALL:
    case League.OLYMPICS_BASKETBALL_WOMEN:
    case League.CBA:
      return Sports.BASKETBALL;
    case League.MLB:
      return Sports.BASEBALL;
    case League.EUFA_CHAMPIONS:
    case League.LA_LLGA:
    case League.ENGLISH_PREMIER:
    case League.FRENCH_LIGUE_1:
    case League.GERMAN_BUNDESLIGA:
    case League.ITALIAN_SERIA_A:
    case League.UEFA_EURO:
    case League.UEFA_EUROPA:
    case League.COPA_AMERICA:
    case League.MLS:
    case League.BRAZIL_SERIE_A:
    case League.OLYMPICS_SOCCER:
    case League.OLYMPICS_SOCCER_WOMEN:
      return Sports.SOCCER;
    case League.TENNIS:
      return Sports.TENNIS;
    default:
      return Sports.SOCCER; // Why Soccer? Most leagues are of soccer anyway
  }
}
