import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { TennisLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/TennisLiveDataTypeFragment";
import { LiveDataDesign } from "../DesignEnum";
import CompactTennisDesign from "./CompactTennisDesign";
import DefaultTennisDesign from "./DefaultTennisDesign";
import DetailedTennisDesign from "./DetailedTennisDesign";
import BasicTennisDesign from "./BasicTennisDesign";

type TennisScoreProps = {
  gameLiveData: TennisLiveDataTypeFragment | null;
  startTime?: number;
  design: LiveDataDesign;
  showAdditionalData?: boolean;
  homeTeamName?: string;
  awayTeamName?: string;
  isMobile?: boolean;
};

function TennisLiveData({ gameLiveData, startTime, homeTeamName, awayTeamName, design, showAdditionalData, isMobile }: TennisScoreProps) {
  const startDate = useMemo(() => (startTime ? fromUnixTime(startTime) : null), [startTime]);
  const elapsedTime = useMemo(() => (startDate ? formatDistanceToNowStrict(startDate, { addSuffix: false }) : ""), [startDate]);
  const currentTime = new Date();
  const isFuture = startDate && startDate > currentTime;

  if (!gameLiveData) {
    return (
      <Box sx={{ textAlign: "center", p: 2 }}>
        <StyledTypography color="text.primary">Game data is unavailable.</StyledTypography>
      </Box>
    );
  }

  const designMap: Record<LiveDataDesign, React.ElementType> = {
    [LiveDataDesign.DEFAULT]: DefaultTennisDesign,
    [LiveDataDesign.COMPACT]: CompactTennisDesign,
    [LiveDataDesign.DETAILED]: DetailedTennisDesign,
    [LiveDataDesign.BASIC]: BasicTennisDesign,

  };

  const DesignComponent = designMap[design] || DefaultTennisDesign;

  return <DesignComponent gameLiveData={gameLiveData} elapsedTime={elapsedTime} isFuture={isFuture} showAdditionalData={showAdditionalData} homeTeamName={homeTeamName} awayTeamName={awayTeamName} isMobile={isMobile} />;
};

export default TennisLiveData;
