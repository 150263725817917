import React from "react";
import { Box, Stack, useTheme } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import { SoccerLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/SoccerLiveDataTypeFragment";
import formatSeconds from "@/utis/formatGameSeconds";
import ScoreIndicatorLiveData from "../ScoreIndicatorLiveData";
import { LiveDataDesign } from "../DesignEnum";
import { formatPeriod } from "../Utils";

type DefaultSoccerDesignProps = {
    gameLiveData: SoccerLiveDataTypeFragment;
    showAdditionalData?: boolean;
};

function DefaultSoccerDesign({ gameLiveData, showAdditionalData }: DefaultSoccerDesignProps) {
    const theme = useTheme();
    const {
        homeTeamScoreByHalf = [0],
        awayTeamScoreByHalf = [0],
        homeTeamHasPossession = false,
        gameTimeSeconds = 0,
        additionalData,
        period,
    } = gameLiveData;

    const homeTeamScore = homeTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const awayTeamScore = awayTeamScoreByHalf?.reduce((acc, curr) => acc + (curr ?? 0), 0);
    const isHomeTeamPossession = homeTeamHasPossession;

    return (
        <Box>
            <Stack direction="column" spacing={0} alignItems="start">
                <StyledTypography variant="body2" pb={1} color="text.secondary">
                    {formatPeriod(period)} {formatSeconds(gameTimeSeconds)}
                </StyledTypography>
                <Stack direction="row" spacing={0} alignItems="start">
                    <Stack direction="row" spacing={1} alignItems="start" sx={{ width: "fit-content", minWidth: "110px" }}>
                        <Stack
                            direction="column"
                            spacing={0}
                            mt={1}
                            sx={{
                                borderRight: `0.5px solid ${theme.palette.border.primary}`,
                                pr: 1,
                            }}
                        >
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {awayTeamScoreByHalf?.map((score, index) => (
                                    <StyledTypography variant="body2" key={index}>
                                        {score}
                                    </StyledTypography>
                                ))}
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                {homeTeamScoreByHalf?.map((score, index) => (
                                    <StyledTypography variant="body2" key={index}>
                                        {score}
                                    </StyledTypography>
                                ))}
                            </Stack>
                        </Stack>

                        <Stack direction="column" spacing={0} alignItems="start" sx={{ flexGrow: 1 }}>
                            <ScoreIndicatorLiveData score={awayTeamScore?.toString()} isServing={!isHomeTeamPossession} design={LiveDataDesign.DEFAULT} />
                            <ScoreIndicatorLiveData score={homeTeamScore?.toString()} isServing={isHomeTeamPossession} design={LiveDataDesign.DEFAULT} />
                        </Stack>
                    </Stack>
                    {
                        showAdditionalData && (
                            <Box>
                                <StyledTypography
                                    lineClamp={3}
                                    mt={0.5}
                                    color="text.secondary"
                                    variant="caption"
                                    sx={{
                                        lineHeight: "1em",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        whiteSpace: "normal",
                                    }}
                                >
                                    {additionalData}
                                </StyledTypography>
                            </Box>
                        )}
                </Stack>
            </Stack>
        </Box>
    );
};

export default DefaultSoccerDesign;
