import { styled, Modal, Box, Typography, Stack, Button, Link } from "@mui/material";
import React, { ComponentProps, useState, useRef } from "react";
import { useTranslations } from "next-intl";
import { PackageTypeEnum } from "@/user/common";
import Image from "next/image";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import BookInfoIcon from "../Icons/BookInfoIcon";
import { contentData } from "../Resources/common/contents";

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.modal.background};
    border: 1px solid ${theme.palette.border.primary};
    backdrop-filter: blur(4px); 
    border-radius:30px;
    padding: ${theme.spacing(3)};
    max-width: 600px;
  `,
);

const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== "textColor",
})<{ textColor?: string }>(
  ({ theme, textColor }) => `
  height: 36px;
  width: fit-content;
  font-size: ${theme.typography.body2.fontSize};
  font-weight: bold;
  align-self: center;
  margin: ${theme.spacing(2)};
  padding: ${theme.spacing(1.5)};
  ${theme.breakpoints.down("md")} {
      padding: ${theme.spacing(0.5)};
  }
`,
);

const StyledVideoWrapper = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  paddingBottom: "40%",
  height: 0,
  overflow: "hidden",
  margin: "0 auto",
  flex: "1 !important",
}));

const StyledImageWrapper = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  paddingBottom: "40%",
  height: 0,
  overflow: "hidden",
  cursor: "pointer",
  margin: "0 auto",
  flex: "1 !important",
}));

const PlayIconWrapper = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "white",
  fontSize: "64px",
}));

type SucessPopupProps = {
  onClose?: () => void;
  activePackageType?: PackageTypeEnum;
} & ComponentProps<typeof StyledBox>;

function SucessPopup({ activePackageType, onClose: onCloseProps, ...restProps }: SucessPopupProps) {
  const [sucessPopup, setSucessPopup] = useState<boolean>(true);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const t = useTranslations("verification");
  const onModalClose = () => {
    setSucessPopup(false);
    onCloseProps?.();
  };

  const handleImageClick = () => {
    setShowVideo(true);
    setTimeout(() => {
      iframeRef.current?.contentWindow?.postMessage({ method: 'play' }, '*');
    }, 100);
  };

  const successPopupContent = contentData.success_popup_video;

  return (
    <StyledModal open={Boolean(sucessPopup)} onClose={onModalClose}>
      <StyledBox height="max-content" margin="auto" pb={3} px={3} {...restProps}>
        <Stack justifyContent="center" alignItems="center" p={1}>
          <Image
            src="/images/logo_name_dark.png"
            alt="Logo"
            width={100}
            height={55}
          />
        </Stack>

        <Stack>
          <Typography variant="header" fontSize={30} textAlign="left" my={1}>
            {t("success_message_title")}
          </Typography>
          {(activePackageType === PackageTypeEnum.STARTER || activePackageType === PackageTypeEnum.INTERMEDIATE) && (
            <Stack direction="row" alignItems="center">
              <Typography variant="caption" textAlign="left" my={1} flex={1}>
                {t("success_popup_message_asc")} <Button onClick={handleImageClick} variant="text" size="small" sx={{ padding: 0, minWidth: 'auto', textTransform: 'none' }}>
                  <Typography variant="caption" textAlign="left" my={1} color="text.primary" sx={{ textDecoration: 'underline', m: 0 }}>
                    {t("watchVideoText")}
                  </Typography>
                </Button> {t("success_popup_message_desc")}
              </Typography>
              {showVideo ? (
                <StyledVideoWrapper flex={1}>
                  <iframe
                    ref={iframeRef}
                    src={successPopupContent.children?.find(child => child.type === "video")?.content}
                    title="Success Popup Video"
                    width="100%"
                    height="100%"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    style={{ border: 0, top: 0, left: 0, position: "absolute", padding: 30, flex: 1 }}
                  />
                </StyledVideoWrapper>
              ) : (
                <StyledImageWrapper onClick={handleImageClick}>
                  <Image
                    src={successPopupContent.children?.find(child => child.type === "image")?.content || "/default-image.png"}
                    alt="Success Popup"
                    fill
                    style={{ padding: 30, objectFit: 'contain' }}
                  />
                  <PlayIconWrapper>
                    <PlayArrowIcon fontSize="inherit" />
                  </PlayIconWrapper>
                </StyledImageWrapper>
              )}
            </Stack>
          )}
          <Typography variant="body2" textAlign="left" my={1}>
            {t("learn_page_intro")} <BookInfoIcon sx={{ fontSize: "12px" }} /> <Link href="/en/resources" color="text.primary">{t("learn_page")}</Link> {t("learn_Page_end")}
          </Typography>
          <Typography variant="body2" textAlign="left" my={1}>
            {t("discord_intro")} <Link href="https://discord.gg/9dsyWsbnRx" target="_blank" color="text.primary">{t("discord")}</Link> {t("discord_end")}
          </Typography>
          <Typography variant="body2" textAlign="left" my={1}>
            {t("enjoy")}
          </Typography>
        </Stack>

        <StyledButton
          variant="contained"
          color="secondary"
          size="small"
          href="/arbitrages">{t("action_button")}</StyledButton>
      </StyledBox>
    </StyledModal>
  );
}

export default SucessPopup;