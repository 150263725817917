export const ICE_HOCKEY_LIVE_DATA_TYPE_FRAGMENT = `
  fragment IceHockeyLiveDataTypeFragment on IceHockeyLiveDataType {
    gameId
    additionalData
    period
    gameTimeSeconds
    homeTeamHasPossession
    awayTeamGoalsByPeriod
    homeTeamGoalsByPeriod
    awayTeamPenalityMinuteByPeriod
    homeTeamPenalityMinuteByPeriod
    awayTeamPenalityCountByPeriod
    homeTeamPenalityCountByPeriod
    awayTeamPenalityShotByPeriod
    homeTeamPenalityShotByPeriod

    players {
      playerId
      position
      forward {
        goals
        assists
        points
        shots
        plusMinus
        faceOffWinPct
        hits
        timeOnIce
        penaltyMinutes
        shifts
      }
      goalie {
        saves
        shotsAgainst
        goalsAgainst
        savesPct
        timeOnIce
      }
    }
    
  }
`;

type IceHockeyLivePlayerForwardDataType = {
  goals: number;
  assists: number;
  points: number;
  shots: number;
  plusMinus: number;
  faceOffWinPct: number;
  hits: number;
  timeOnIce: string;
  penaltyMinutes: number;
  shifts: number;
};

type IceHockeyLivePlayerGoalieDataType = {
  saves: number;
  shotsAgainst: number;
  goalsAgainst: number;
  savesPct: number;
  timeOnIce: string;
};

export enum IceHockeyLivePlayerBasicPositionEnum {
  FORWARD = "FORWARD",
  DEFENSEMAN = "DEFENSEMAN",
  GOALIE = "GOALIE",
}

export type IceHockeyLivePlayerDataType = {
  playerId: number;
  position: IceHockeyLivePlayerBasicPositionEnum | null;
  forward: IceHockeyLivePlayerForwardDataType | null;
  goalie: IceHockeyLivePlayerGoalieDataType | null;
};

export type IceHockeyLiveDataTypeFragment = {
  gameId: string;
  additionalData: string;
  period: string;
  gameTimeSeconds: number;
  homeTeamHasPossession: boolean;
  awayTeamGoalsByPeriod: number[] | null;
  homeTeamGoalsByPeriod: number[] | null;
  awayTeamPenalityMinuteByPeriod: number[] | null;
  homeTeamPenalityMinuteByPeriod: number[] | null;
  awayTeamPenalityCountByPeriod: number[] | null;
  homeTeamPenalityCountByPeriod: number[] | null;
  awayTeamPenalityShotByPeriod: number[] | null;
  homeTeamPenalityShotByPeriod: number[] | null;
  players: IceHockeyLivePlayerDataType[] | null;
};
