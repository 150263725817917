import * as Sentry from "@sentry/nextjs";

const DEFAULT_TTL = 60 * 60 * 1000;
const CACHE_VERSION_KEY = "CONSTANT_CACHE_KEY";

// Sometime local storage is not available or permission is not there
const isLocalStorageAvailable = () => {
  try {
    const testKey = "__test__";
    localStorage.setItem(testKey, "test");
    localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("localStorage is not available:", error);
    return false;
  }
};

// So fallback to map if localstorage is not available
const storage = isLocalStorageAvailable() ? localStorage : new Map();

const getItemFromStorage = (key: string) => ("getItem" in storage ? storage.getItem(key) : storage.get(key));
const setItemFromStorage = (key: string, value: string) =>
  "setItem" in storage ? storage.setItem(key, value) : storage.set(key, value);
const removeItemFromStorage = (key: string) => ("removeItem" in storage ? storage.removeItem(key) : storage.delete(key));

export function loadState<T>(key: string): T | null {
  if (typeof window === "undefined") return null;
  try {
    const serializedState = getItemFromStorage(key);
    if (!serializedState) return null;
    return JSON.parse(serializedState);
  } catch (e) {
    // Sentry.withScope((scope) => {
    //   scope.setExtra("key", key);
    //   scope.setExtra("Error", e);
    //   Sentry.captureException(new Error(`Custom Error - Could not Parse LocalStorage for key - ${key}`));
    // });
    return null;
  }
}

export function deleteState(key: string) {
  if (typeof window === "undefined") return;
  removeItemFromStorage(key);
}

export function saveState<T>(key: string, state: T) {
  if (typeof window === "undefined") return;
  try {
    if (state !== undefined) {
      const serializedState = JSON.stringify(state);
      setItemFromStorage(key, serializedState);
    } else {
      // if value is undefined, don't update the key. Just delete it
      deleteState(key);
    }
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setExtra("key", key);
      scope.setExtra("Error", e);
      Sentry.captureException(new Error(`Custom Error - Could not Store in LocalStorage for key - ${key}`));
    });
  }
}

export function loadStateWithExpiry<T>(key: string): T | null {
  if (typeof window === "undefined") return null;
  if (loadState<string>(CACHE_VERSION_KEY) !== process.env.NEXT_PUBLIC_CONSTANT_CACHE_KEY) {
    saveState(CACHE_VERSION_KEY, process.env.NEXT_PUBLIC_CONSTANT_CACHE_KEY);
    return null;
  }

  const item = <{ state: T; expiry: number }>loadState(key);
  if (!item) {
    return null;
  }
  const now = new Date();
  if (now.getTime() > item.expiry) {
    deleteState(key);
    return null;
  }
  return item.state;
}

export function setStateWithExpiry<T>(key: string, state: T, ttl?: number) {
  const now = new Date();
  const item = {
    state,
    expiry: now.getTime() + (ttl ?? DEFAULT_TTL),
  };
  saveState(key, item);
}
