import React, { ComponentProps } from "react";
import { Box } from "@mui/material";
import { useTranslations } from "next-intl";
import LogoProgressSpinner from "../LogoProgressSpinner";

type PageSpinnerProps = {
  text?: string;
  hideText?: boolean;
} & ComponentProps<typeof Box>;

function PageSpinner({ text, hideText, ...restProps }: PageSpinnerProps) {
  const t = useTranslations("words");
  return (
    <Box justifyContent="center" alignItems="center" display="flex" flexDirection="column" height="50vh" {...restProps}>
      <LogoProgressSpinner />
      {
        !hideText && (<>
          &nbsp;&nbsp;&nbsp;{text || t("searching")}
        </>)
      }
    </Box >
  );
}

export default PageSpinner;
