import { useEffect, useState } from 'react';
import useSWR from 'swr';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export function useBuildId() {
    const { data, error } = useSWR('/api/build-id', fetcher, {
        refreshInterval: 259200000,
        // Disable polling in development
        ...(process.env.NODE_ENV !== 'production' && {
            refreshInterval: 0,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        })
    });

    // Early return for development
    if (process.env.NODE_ENV !== 'production') {
        return {
            buildId: null,
            isLoading: false,
            isError: false
        };
    }

    return {
        buildId: data?.buildId,
        isLoading: !error && !data,
        isError: error
    };
}

export function usePrevious<T>(value: T): T | undefined {
    const [prev, setPrev] = useState<T>();
    useEffect(() => {
        setPrev(value);
    }, [value]);
    return prev;
}