import { IGameLiveDataType } from "@/components/Common/GameLiveData/common";
import { PartialRecord } from "@/utis/partialRecord";
import { PayloadAction, Store, createSlice } from "@reduxjs/toolkit";
import { differenceInSeconds } from "date-fns";

// maybe it is null and not undefined, need to change on the filter component also
export type GameLiveDataType = {
  lastUpdateTime: number;
} & IGameLiveDataType;

let storeInstance: Store;

export const setStoreReference = (store: Store) => {
  storeInstance = store;
};

const initialState: PartialRecord<string, GameLiveDataType> = {};
const timers: PartialRecord<string, NodeJS.Timeout> = {};

const CLEANUP_INTERVAL = 30;
export const gameLiveData = createSlice({
  name: "gameLiveData",
  initialState,
  reducers: {
    setGameLiveData: (state, action: PayloadAction<IGameLiveDataType>) => {
      const game = action.payload;
      const newState = { ...state };
      newState[game.gameId] = { ...game, lastUpdateTime: new Date().getTime() };

      if (timers[game.gameId]) {
        clearTimeout(timers[game.gameId]);
      }

      timers[game.gameId] = setTimeout(() => {
        if (storeInstance) {
          storeInstance.dispatch({
            type: 'gameLiveData/removeGame',
            payload: game.gameId
          });
        }
      }, CLEANUP_INTERVAL * 1000);

      return newState;
    },
    removeGame: (state, action: PayloadAction<string>) => {
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    },
    setGameLiveDataList: (state, action: PayloadAction<IGameLiveDataType[]>) => {
      const games = action.payload;
      const newState = { ...state };
      games.forEach((game) => {
        newState[game.gameId] = { ...game, lastUpdateTime: new Date().getTime() };
      });
      return newState;
    },
    cleanGameLiveData: (state) => {
      const currDate = new Date();
      const newList = Object.values(state).filter((game) => differenceInSeconds(currDate, game!.lastUpdateTime));
      const newState = newList.reduce<Record<string, GameLiveDataType>>((acc, curr) => {
        acc[curr!.gameId] = curr!;
        return acc;
      }, {});
      return newState;
    },
  },
});

export const gameLiveDataAction = gameLiveData.actions;

export default gameLiveData.reducer;
