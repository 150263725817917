import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { League } from '@/components/Common/Enums/LeagueEnum';
import { BlockedBetRowType } from '@/components/BlockedBets/common';

interface TrackBetModalState {
    isOpen: boolean;
    gameId: string | null;
    league: League | null;
    betMarketHashCodes: number[] | null;
    betData?: BlockedBetRowType | null;
    onBlockedBet?: () => void;
}

const initialState: TrackBetModalState = {
    isOpen: false,
    gameId: null,
    league: null,
    betMarketHashCodes: null,
    betData: undefined,
    onBlockedBet: undefined,
};

const trackBetModalSlice = createSlice({
    name: 'trackBetModal',
    initialState,
    reducers: {
        setTrackBetData(state, action: PayloadAction<{ gameId: string; league: League; betMarketHashCodes: number[]; bet?: BlockedBetRowType; onBlockedBet?: () => void; }>) {
            return {
                ...state,
                isOpen: true,
                gameId: action.payload.gameId,
                league: action.payload.league,
                betMarketHashCodes: action.payload.betMarketHashCodes,
                betData: action.payload.bet,
                onBlockedBet: action.payload.onBlockedBet,

            };
        },
        closeModal(state) {
            return {
                ...state,
                isOpen: false,
                gameId: null,
                league: null,
                betMarketHashCodes: null,
                betData: undefined,
            };
        },
    },
});

export const { setTrackBetData, closeModal } = trackBetModalSlice.actions;
export default trackBetModalSlice.reducer;