import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function TrackBetIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 31 28"
            {...props}
        >
            <path
                d="M21.5 13.67L27.86 2.675L30.455 4.175L22.61 17.75L12.845 12.125L5.69 24.5H30.5V27.5H0.5V0.5H3.5V22.31L11.75 8L21.5 13.67Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}

export default TrackBetIcon;
