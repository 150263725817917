import { Sports } from "@/components/Common/Enums/SportsEnum";
import { League as LeagueEnum } from "@/components/Common/Enums/LeagueEnum";
import { BetMarketTypeTimeEnum } from "@/components/Common/Enums/BetMarketTypeTimeEnum";
import { loadStateWithExpiry, setStateWithExpiry } from "@/utis/localStorage";
import { BET_MARKET_TYPE_GROUP_ENUM } from "@/components/Common/Enums/BetMarketTypeGroupEnum";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FetchAllAvailablePlansConstantsTypeFragment } from "@/components/AvailablePlans/common";
import { stringContainsPattern } from "@/utis/string";
import { BetSiteRatingEnum } from "@/components/Common/Enums/BetSiteRatingEnum";
import { BetMarketSiteBetSiteTypeFragment } from "@/components/Common/Fragments/BetMarketSiteBetSiteTypeFragment";

const KEY = "CONSTANTS";

export type BetMarketInfo = {
  displayName: string;
  value: string;
  groupValue: BET_MARKET_TYPE_GROUP_ENUM;
  order: number;
  time: BetMarketTypeTimeEnum;
  templateString: string;
};

type BetMarketSiteBetSiteType = {
  isSisterSite: boolean;
  sisterSiteIds: number[] | null;
  enumValue: string;
  active: boolean;
  ratings: BetSiteRatingEnum[];
} & BetMarketSiteBetSiteTypeFragment;

export type BetMarketGroups = {
  value: string;
  order: number;
};

export type Leagues = {
  id: LeagueEnum;
  sportId: Sports;
  isActive: boolean;
};

// maybe it is null and not undefined, need to change on the filter component also
export type ConstantsType = {
  betMarketInfo: BetMarketInfo[] | undefined;
  betGroups: BetMarketGroups[] | undefined;
  leagues: Leagues[] | undefined;
  betSites: BetMarketSiteBetSiteType[];
  betSitesParent: Partial<Record<string, string | null>>;
  betSiteSister: Partial<Record<string, string[]>>;
  betSiteMap: Record<string, BetMarketSiteBetSiteType>;
} & FetchAllAvailablePlansConstantsTypeFragment;

function getBetSitesParent(betSites: BetMarketSiteBetSiteType[]) {
  const betSiteIdMap = betSites.reduce<Record<number, string>>((acc, curr) => {
    acc[curr.id] = curr.enumValue;
    return acc;
  }, {});
  const betSiteSister: Record<string, string[]> = {};
  const betSitesParent = betSites
    .filter((bs) => !bs.isSisterSite)
    .reduce<Record<string, string | null>>((acc, curr) => {
      acc[curr.enumValue] = null;
      if (curr.sisterSiteIds?.length) {
        betSiteSister[curr.enumValue] = curr.sisterSiteIds?.map((sisId) => betSiteIdMap[sisId]);
      }
      curr.sisterSiteIds?.forEach((sisId) => {
        acc[betSiteIdMap[sisId]] = curr.enumValue;
      });
      return acc;
    }, {});
  return { betSitesParent, betSiteSister, betSiteIdMap };
}

const getDefaultStateUpdated = (key: string) => {
  const defaultState = loadStateWithExpiry<ConstantsType>(key);

  // always check for latest constant to present
  if (!defaultState || defaultState.betSites?.[0]?.ratings === undefined) return null;
  if (!Object.keys(defaultState.betSiteMap).length) {
    const { betSitesParent, betSiteSister } = getBetSitesParent(defaultState.betSites);
    const betSiteMap = defaultState.betSites.reduce<Record<string, BetMarketSiteBetSiteType>>((acc, curr) => {
      acc[curr.enumValue] = curr;
      return acc;
    }, {});
    return { ...defaultState, betSitesParent, betSiteSister, betSiteMap };
  }
  return defaultState;
};

const initialState: ConstantsType = getDefaultStateUpdated(KEY) || {
  betGroups: undefined,
  betMarketInfo: undefined,
  leagues: undefined,
  restrictions: null,
  betSites: [],
  betSitesParent: {},
  betSiteSister: {},
  betSiteMap: {},
};

export const sortSportsBookWithId = (sportsBookSortId: number[] | undefined, betSites: BetMarketSiteBetSiteType[]) => {
  if (!sportsBookSortId) return betSites;
  const sortedSportsBookList = betSites.slice().sort((a, b) => {
    const indexA = sportsBookSortId.findIndex((sbSid) => sbSid === a.id);
    const indexB = sportsBookSortId.findIndex((sbSid) => sbSid === b.id);

    // If not found, assign Infinity
    const finalIndexA = indexA === -1 ? Infinity : indexA;
    const finalIndexB = indexB === -1 ? Infinity : indexB;

    return finalIndexA - finalIndexB;
  });
  return sortedSportsBookList;
};

type ConstantsTypeWithSort = {
  sportsBookSortId: number[] | undefined;
} & ConstantsType;

export const constants = createSlice({
  name: "constants",
  initialState,
  reducers: {
    setConstants: (_, action: PayloadAction<ConstantsTypeWithSort>) => {
      const betSites = sortSportsBookWithId(action.payload.sportsBookSortId, action.payload.betSites);
      const { betSitesParent, betSiteSister } = getBetSitesParent(betSites);
      const betSiteMap = betSites.reduce<Record<string, BetMarketSiteBetSiteType>>((acc, curr) => {
        acc[curr.enumValue] = curr;
        return acc;
      }, {});
      const betMarketInfo: BetMarketInfo[] = (action.payload?.betMarketInfo ?? []).map((element) => {
        let type = element.value;
        if (element.time !== BetMarketTypeTimeEnum.GAME) {
          type = type.replace(`_${element.time}`, "");
        }
        const pattern = stringContainsPattern(type, [BetMarketTypeTimeEnum.TO_QUALIFY, BetMarketTypeTimeEnum.NO_OT]);
        if (pattern !== "") {
          type = type.replace(`_${pattern}`, "");
        }
        return {
          ...element,
        } as BetMarketInfo;
      });
      const newState = {
        ...action.payload,
        betSites,
        betMarketInfo,
        betSitesParent,
        betSiteSister,
        betSiteMap,
      };
      setStateWithExpiry(KEY, newState);
      return newState;
    },
    updateBetSites: (state, action: PayloadAction<BetMarketSiteBetSiteType[]>) => {
      const newState = {
        ...state,
        betSites: action.payload,
        ...getBetSitesParent(action.payload),
        betSiteMap: action.payload.reduce<Record<string, BetMarketSiteBetSiteType>>((acc, curr) => {
          acc[curr.enumValue] = curr;
          return acc;
        }, {}),
      };
      setStateWithExpiry(KEY, newState);
      return newState;
    },
    sortBetSitesById: (state, action: PayloadAction<number[]>) => {
      const sortedSportsBookList = sortSportsBookWithId(action.payload, state.betSites);
      const newState = state;
      newState.betSites = sortedSportsBookList;
      setStateWithExpiry(KEY, newState);
      return newState;
    },
  },
});

export const constantsAction = constants.actions;

export default constants.reducer;
