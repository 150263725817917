import { addDays, addHours, addMinutes, addMonths } from "date-fns";
import { TeamType, ToolsSubscriptionFragment } from "../Common/Fragments/ToolsSubscriptionFragment"

export enum BlockedBetsDurationType {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES'
}

export const BlockedBetsDurationTypeMssgMap = {
  [BlockedBetsDurationType.DAYS]: 'days',
  [BlockedBetsDurationType.MONTHS]: 'months',
  [BlockedBetsDurationType.HOURS]: 'hours',
  [BlockedBetsDurationType.MINUTES]: 'minutes'
}

export type BlockedBetDateType = {
  expirationDate: number,
}

export type BlockedBetRowType = {
  teams: (TeamType | null)[],
  gameStartTime: number | undefined,
  displayName: string | undefined,
} & Pick<ToolsSubscriptionFragment, 'betMarketSiteIds' | 'id' | 'betValues' | 'overOrUnder' | 'betMarketId'>;

export type BlockedBetsBetType = BlockedBetDateType & BlockedBetRowType;


export const getExpirationDateTimeFromDuration = (durationValue: number, durationType: BlockedBetsDurationType) => {
  switch (durationType) {
    case BlockedBetsDurationType.DAYS:
      return addDays(Date.now(), durationValue).getTime();
    case BlockedBetsDurationType.MONTHS:
      return addMonths(Date.now(), durationValue).getTime();
    case BlockedBetsDurationType.HOURS:
      return addHours(Date.now(), durationValue).getTime();
    case BlockedBetsDurationType.MINUTES:
      return addMinutes(Date.now(), durationValue).getTime();
    default:
      return Date.now();
  }
};


// This function takes a timestamp as input and calculates the difference between the current date and the target date (timestamp).
// It returns an array of strings representing the difference in days, hours, and minutes, each padded to two digits.
export const formatDateTimeWithLabels = (timestamp: number) => {
  const now = new Date();
  const targetDate = new Date(timestamp);
  const difference = targetDate.getTime() - now.getTime();

  if (difference <= 0) {
    return ['00', '00', '00'];
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  return [
    String(days).padStart(2, '0'),
    String(hours).padStart(2, '0'),
    String(minutes).padStart(2, '0')
  ];
};