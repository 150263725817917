import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { useTranslations } from "next-intl";

import { karantina } from "@/utis/fonts";
import AvailablePlans from "../AvailablePlans";

const StyledTitle = styled(Typography)(
  ({ theme }) => `
  font-size: 80px;
  line-height: 1;
  text-transform: uppercase;
  padding: 0px 8px;
  margin-left: 8px;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: ${karantina.style.fontFamily};
  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 54px;
    padding: 0px;
  }
`
);

const StyledContainer = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(10)} ${theme.spacing(7)};
  position: relative;
  background: ${theme.palette.customBackground.cards};
  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: ${theme.spacing(5)} ${theme.spacing(3)};
  }
`
);

function PlansAndPayment() {
  const t = useTranslations("Home");
  return (
    <StyledContainer className="section">
      <Box sx={{ maxWidth: "1212px" }} justifyContent="center" ml="auto" mr="auto" pb="96px">
        <StyledTitle>{t("plan_title")}</StyledTitle>
        <AvailablePlans hideTopBtn flexGrow={1} />
      </Box>
    </StyledContainer>
  );
}

export default PlansAndPayment;