import React from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import StyledTypography from "@/components/StyledComponents/EllipseTypography";
import formatSeconds from "@/utis/formatGameSeconds";
import { formatPeriodBasic } from "./Utils";

type BasicGameDesignProps = {
    period: string;
    gameTimeSeconds?: number;
    awayTeamAbbr: string;
    homeTeamAbbr: string;
    awayTeamScore: number | string | undefined;
    homeTeamScore: number | string | undefined;
    isMobile?: boolean;
};

function BasicGameDesign({ period, gameTimeSeconds, awayTeamAbbr, homeTeamAbbr, awayTeamScore, homeTeamScore, isMobile }: BasicGameDesignProps) {
    const formattedPeriod = formatPeriodBasic(period);
    const formattedGameTime = gameTimeSeconds !== undefined ? formatSeconds(gameTimeSeconds) : "";
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
    const isMobileScreen = isMobile || isMdDown;

    return (
        <Stack direction="column" >
            <Stack direction="row" alignItems="center" gap={0.5}>
                <StyledTypography variant="label" color="text.primary" sx={{ opacity: 0.3 }}>{formattedPeriod}</StyledTypography>
                {gameTimeSeconds !== undefined && (
                    <>
                        <StyledTypography variant="label" sx={{ opacity: 0.7 }}>•</StyledTypography>
                        <StyledTypography variant="label" color="text.primary" sx={{ opacity: 0.3 }}>{formattedGameTime}</StyledTypography>
                    </>
                )}
            </Stack>
            <Stack direction={isMobileScreen ? "row" : "column"} gap={0.5} ml={isMobileScreen ? 0 : -2.5} sx={{ opacity: 0.6 }}>
                <Stack direction="row" alignItems="center" gap={0.5}>
                    <StyledTypography variant="label" maxWidth={50}>{awayTeamAbbr}</StyledTypography>
                    <StyledTypography variant="label" fontWeight={700}>{awayTeamScore}</StyledTypography>
                </Stack>
                {isMobileScreen && <StyledTypography variant="label">•</StyledTypography>}
                <Stack direction="row" alignItems="center" gap={0.5}>
                    <StyledTypography variant="label" maxWidth={50}>{homeTeamAbbr}</StyledTypography>
                    <StyledTypography variant="label" fontWeight={700}>{homeTeamScore}</StyledTypography>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default BasicGameDesign;