import { GameLiveDataType } from "@/store/gameLiveData";
import { sumArray } from "@/utis/sumArray";
import { Sports } from "../Common/Enums/SportsEnum";
import { BasketballLiveDataTypeFragment } from "../Common/GameLiveData/Fragments/BasketballLiveDataTypeFragment";
import { FootballLiveDataTypeFragment } from "../Common/GameLiveData/Fragments/FootballLiveDataTypeFragment";

// footballUtils.ts
export const determineYardsToGo = (
  yardsToGo: string,
  homeTeamAbbr?: string,
  awayTeamAbbr?: string,
  homeTeamHasPossession?: boolean,
): string => {
  const yards = parseInt(yardsToGo, 10);

  if (Number.isNaN(yards) || !homeTeamAbbr || !awayTeamAbbr) return "00";

  const teamWithPossession = homeTeamHasPossession ? homeTeamAbbr : awayTeamAbbr;

  if (yards >= 50) {
    const displayYards = 50 - (yards - 50);
    return `${teamWithPossession} ${displayYards}`;
  }

  const opponentTeam = homeTeamHasPossession ? awayTeamAbbr : homeTeamAbbr;
  return `${opponentTeam} ${yards}`;
};

export const formatPeriod = (period: string): string => {
  if (period === "Halftime") return "Half";

  const periodNumber = parseInt(period.replace(/[QP]/, ""), 10);
  if (Number.isNaN(periodNumber)) return "00";

  let suffix = "th";

  if (periodNumber % 10 === 1 && periodNumber % 100 !== 11) {
    suffix = "st";
  } else if (periodNumber % 10 === 2 && periodNumber % 100 !== 12) {
    suffix = "nd";
  } else if (periodNumber % 10 === 3 && periodNumber % 100 !== 13) {
    suffix = "rd";
  }

  return `${periodNumber}${suffix}`;
};

export const formatPeriodBasic = (period: string): string => {
  if (period === "Halftime") return "Half";

  const periodNumber = parseInt(period.replace(/[QP]/, ""), 10);
  if (Number.isNaN(periodNumber)) return period;

  return `Q${periodNumber}`;
};

export const isInRedZone = (yardsDistance: string): boolean => {
  const yardsDistanceNumber = parseInt(yardsDistance, 10);
  if (Number.isNaN(yardsDistanceNumber)) return false;
  return yardsDistanceNumber <= 20;
};

// Function to customize the formatting of elapsed time
export const formatCustomElapsedTime = (elapsedTime: string) =>
  elapsedTime
    .replace("minute", "min") // Replace "minute" with "min"
    .replace("minutes", "min") // Replace "minutes" with "min"
    .replace("second", "sec") // Replace "second" with "sec" if needed
    .replace("seconds", "sec"); // Replace "seconds" with "sec" if needed

export const getLiveScore = (gameLiveData: GameLiveDataType, sports: Sports) => {
  switch (sports) {
    case Sports.BASKETBALL: {
      const livedata = gameLiveData as BasketballLiveDataTypeFragment;
      return [sumArray(livedata.awayTeamTotalPointsByQuarter), sumArray(livedata.homeTeamTotalPointsByQuarter)];
    }
    case Sports.FOOTBALL: {
      const livedata = gameLiveData as FootballLiveDataTypeFragment;
      return [sumArray(livedata.awayTeamTotalPoints), sumArray(livedata.homeTeamTotalPoints)];
    }
    default:
      break;
  }
  return [null, null];
};
