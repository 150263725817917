import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { fromUnixTime, formatDistanceToNowStrict } from "date-fns";
import { BaseballLiveDataTypeFragment } from "@/components/Common/GameLiveData/Fragments/BaseballLiveDataTypeFragment";
import { LiveDataDesign } from "../DesignEnum";
import DefaultBaseballDesign from "./DefaultBaseballDesign";
import CompactBaseballDesign from "./CompactBaseballDesign";
import DetailedBaseballDesign from "./DetailedBaseballDesign";
import BasicBaseBallDesign from "./BasicBaseBallDesign";

type BaseballLiveDataProps = {
    gameLiveData: BaseballLiveDataTypeFragment | null;
    startTime?: number;
    design: LiveDataDesign;
    showAdditionalData?: boolean;
    isMobile?: boolean;
    awayTeamAbbr?: string;
    homeTeamAbbr?: string;
};

function BaseballLiveData({ gameLiveData, startTime, design, showAdditionalData, isMobile, awayTeamAbbr, homeTeamAbbr }: BaseballLiveDataProps) {
    const startDate = useMemo(() => (startTime ? fromUnixTime(startTime) : null), [startTime]);
    const elapsedTime = useMemo(() => (startDate ? formatDistanceToNowStrict(startDate, { addSuffix: false }) : ""), [startDate]);
    const currentTime = new Date();
    const isFuture = startDate && startDate > currentTime;

    if (!gameLiveData) {
        return (
            <Box sx={{ textAlign: "center", p: 2 }}>
                <p>Game data is unavailable.</p>
            </Box>
        );
    }

    const designMap: Record<LiveDataDesign, React.ElementType> = {
        [LiveDataDesign.DEFAULT]: DefaultBaseballDesign,
        [LiveDataDesign.COMPACT]: CompactBaseballDesign,
        [LiveDataDesign.DETAILED]: DetailedBaseballDesign,
        [LiveDataDesign.BASIC]: BasicBaseBallDesign
    };

    const DesignComponent = designMap[design] || DefaultBaseballDesign;

    return <DesignComponent gameLiveData={gameLiveData} elapsedTime={elapsedTime} isFuture={isFuture} showAdditionalData={showAdditionalData} isMobile={isMobile} awayTeamAbbr={awayTeamAbbr} homeTeamAbbr={homeTeamAbbr} />;
}

export default BaseballLiveData;
